import React, { useState } from "react";
import { useAuth } from "../components/Firebase";
import { ErrorMessage } from "../components/common";

// Material-ui imports
import {
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  Link,
  TextField,
  Typography,
} from "@material-ui/core";

import LockRoundedIcon from "@material-ui/icons/LockRounded";

const Login = () => {
  const [formValues, setFormValues] = useState({ email: "", password: "" });
  const { firebase } = useAuth();
  const [errorMessage, setErrorMessage] = useState("");

  function handleSubmit(e) {
    e.preventDefault();
    firebase
      .login({ email: formValues.email, password: formValues.password })
      .catch((error) => {
        console.log(error);
        setErrorMessage(error.message);
      });
  }

  function handleInputChange(e) {
    e.persist();
    setErrorMessage("");
    setFormValues((currentValues) => ({
      ...currentValues,
      [e.target.name]: e.target.value,
    }));
  }

  return (
    <Container maxWidth="sm">
      <Grid item xs={12}>
        <Box className="form-block">
          <Box className="icon-heading">
            <Avatar>
              <LockRoundedIcon />
            </Avatar>
            <Typography variant="h4" component="h2">
              Log in
            </Typography>
          </Box>
          <form onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              required
              fullWidth
              label="Email Address"
              name="email"
              autoComplete="email"
              value={formValues.email}
              onChange={handleInputChange}
              autoFocus
            />
            <TextField
              variant="outlined"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              autoComplete="current-password"
              value={formValues.password}
              onChange={handleInputChange}
            />
            {!!errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
            <Button type="submit" fullWidth variant="contained" color="primary">
              Log in
            </Button>
          </form>
          <Link href="/register">Don't have an account? Sign up</Link>
        </Box>
      </Grid>
    </Container>
  );
};

export default Login;
